import React from 'react'


const Contact = () => {

    return (
        <>
        <iframe id="typeform-full" width="100%" height="100%" frameborder="0" 
            allow="camera; microphone; autoplay; encrypted-media;" 
            src="https://form.typeform.com/to/kAUMSlPL?typeform-medium=embed-snippet">
            </iframe> 
            
        </>
    )

}

export default Contact