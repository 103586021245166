import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import AppHero from '../components/AppHero'
import Dltest from '../components/Dl/dltest'
import Faq from '../components/Faq/faq'
import Feat from '../components/Listfeat/featlist';
import Covid from '../components/Covid/covid';
import Services from '../components/Services/services'
import MapCom from '../components/Map/MapCom';


const IndexPage = () => (
  <Layout>
    <AppHero />
    
    <Feat  />
    <MapCom />
    <Services  /> 
    <Dltest />
    <Covid />
    <Faq />
  </Layout>
)

export default IndexPage
