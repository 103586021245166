import React from 'react'
import { Link } from 'gatsby'
import ReactModal from 'react-modal'
import styled from 'styled-components';
import './index.css'
import pic from '../../images/illbg.png'
import apple from '../../images/apple-logo.png'
import android from '../../images/android_logo.png'
import bgPath from '../../images/homBg.jpg'
import doconphone from '../../images/doconphone.png'
import {IoIosCloseCircleOutline} from 'react-icons/io'
import TextLoop from "react-text-loop";
import { FaDiagnoses } from 'react-icons/fa'
import { FaUserMd } from 'react-icons/fa'
import { FaCommentMedical } from 'react-icons/fa'


const Container = styled.div`
  ${tw`container pt-18 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center`};
`

const StyledLink = styled(Link)`
  ${tw``};
`
const BG = styled.div`
  ${tw` w-full bg-right bg-cover `};
`
ReactModal.setAppElement('#___gatsby')


function isMobileDevice() {
	if (typeof window !== 'undefined') {
	return ( window.orientation > -1) || (navigator.userAgent.indexOf('IEMobile') !== -1);
	}
};

const customStyles = {
	content : {
	  top                   : isMobileDevice() ? '55%' : '50%',
	  left                  : '50%',
	  right                 : 'auto',
	  bottom                : 'auto',
	  marginRight           : '-50%',
	  transform             : 'translate(-50%, -50%)',
	  height:  500,
	  width: isMobileDevice() ? '90%' : '55%',
	},
	overlay: {zIndex: 50}
  };


const AppHero = () => {

	const [openModal, setModal] = React.useState(false)

	function handleModal(){
		setModal(!openModal)
	} 

	React.useEffect(() => {
		
	})


return (
  <Container>
	  
	  <ReactModal 
		isOpen={openModal}
		// onAfterOpen={afterOpenModal}
		onRequestClose={handleModal}
		style={customStyles}
		> 
			<button class="flex justify-self-end self-end absolute top-0 pt-2 cursor-pointer z-50" onClick={handleModal}> 
			<IoIosCloseCircleOutline class="text-gray-600"/>
			</button>
			<br/>
			<iframe id="medbot" src='https://www.mediktor.com/en-us/chatbot' class="overflow-x-hidden overflow-y-hidden -mt-32 z-50 pt-4 w-full h-full" ></iframe> 

		</ReactModal>
        <BG > 
		
		{/* style={{backgroundImage:`url(${bgPath})`}} */}
			{/* <img  src={bgPath} class="bgImg">  */}
		{
			<div  class="wrapper bg-top md:bg-center flex flex-wrap h-screen bg-transparent overflow-hidden ">
				<div class="w-full justify-start flex indexpage mx-4">
					<div class="self-center w-full md:w-2/3 z-10 md:pl-4">
						
						<div class="self-center">
						{/* <section class="p-2 bg-indigo-800 swing rounded" >       */}
						<TextLoop noWrap={false} interval={4000} className="md:w-11/12">
							<p class="text-indigo-900 tracking-tight md:tracking-wide font-medium md:font-bold text-justify md:text-left" style={{fontSize:isMobileDevice()?45:55}}>
								{/* Realtime healthcare access at your own comfort */}
								{/* Get prescriptions delivered at your doorstep & 
								Home serviced test sample collection at your convenience */}
								Access healthcare in realtime at your <span class="underline">comfort</span>
							</p>
							<p class="text-indigo-900 tracking-tight md:tracking-wide font-medium md:font-bold text-justify md:text-left" style={{fontSize:isMobileDevice()?45:55}}>
								Prescriptions delivered at your <span class="underline">doorstep</span>
							</p>
							<p class="text-indigo-900 tracking-tight md:tracking-wide font-medium md:font-bold text-justify md:text-left" style={{fontSize:isMobileDevice()?45:55}}>
								Home-serviced test sample collection & <span class="underline">delivery</span>
							</p>
							
						</TextLoop>
							
							<p class="text-gray-700 tracking-tight md:tracking-wide leading-wide "> 
								{/* Consult remotely at your own convenience 24/7 */}
								Offer or receive medical consultancy services on the go 24/7

							</p>

						{/* </section> */}
							{/* <h1 class="text-indigo-800 font-bold">Realtime Health Consultation with verified Professionals</h1> */}
							<div class="pt-5 indexbutton flex">
							{/* <StyledLink > */}
							{ !isMobileDevice() ? <div class="grid grid-cols-1 md:grid-cols-3 gap-5">
								<div onClick={handleModal} class="bg-gray-200 cursor-pointer rounded flex flex-col items-center justify-center text-center text-md text-gray-800 pulse-shrink-on-hover fxDsp">
									<FaDiagnoses  style={{fontSize:30, marginTop:10}}/>
									<span class="p-4">Check your symptom</span>
								</div>
								<StyledLink to="/medical-professionals">
								<div class=" bg-green-200 cursor-pointer rounded flex flex-col items-center justify-center text-center text-md text-green-800 pulse-shrink-on-hover fxDsp">
									
										<FaUserMd  style={{fontSize:30, marginTop:10}}/>
										<span class="p-4">Apply as a professional </span>
									
								</div></StyledLink>
								<StyledLink to="/patients">
								<div class=" bg-purple-200 cursor-pointer rounded flex flex-col items-center justify-center text-center text-md text-purple-800 pulse-shrink-on-hover fxDsp">
									
									<FaCommentMedical  style={{fontSize:30, marginTop:10}}/>
									<span class="p-4">Talk to a doctor</span>
									
								</div>
								</StyledLink>
							</div>
								: <>
								<button onClick={handleModal} class="bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 mx-1border border-indigo-700 rounded">
									 Check your symptom 
								</button>
							
							<StyledLink to="/patients">
								<button class="bg-transparent hover:bg-indigo-800 text-indigo-700 font-semibold hover:text-white py-2 mx-1 px-4 border border-indigo-700 hover:border-transparent rounded">
									 Talk to a doc
								</button>
							</StyledLink>
							</>}

							</div>
						</div>
					</div>
				</div>
				{/* <div class="w-1/2 flex self-center indeximage" > */}
					{/* <div class="flex flex-row text-center justify-center">
						<p class="text-gray-500  text-center text-lg align-baseline font-thin m-0">
            				Available On
						</p>
						<img src={apple} class="cursor-pointer h-8 px-5 m-0 hvr-push" />
						<img src={android} class="cursor-pointer h-8 m-0 hvr-push" />
					</div> */}
					{/* <img src={pic} alt="" style={{height:500}} class="fade-in" /> */}
					
				{/* </div> */}

				<ul class="bg-bubbles">
					<li class="hidden"></li>
					<li ></li>
					<li class="hidden"></li>
					<li></li>
					<li></li>
					<li class="hidden md:flex"></li>
					<li></li>
					<li></li>
					<li></li>
					<li class="hidden"></li>
				</ul>
			</div>
		}
			</BG>	
  </Container>
)}

export default AppHero
