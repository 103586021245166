import React, { useState } from 'react';
import pic from '../../images/black-doctor.png';
import doctor from '../../images/doctor.png'
import human from '../../images/human-spin.gif'
import hospital from '../../images/hospital.png'
import insurance from '../../images/insurance.png'
import shield from '../../images/shield.png'
import '../Info/Info.css';


const Feat = () => {
return (
    <div class="mt-4">
        <h1 class="font-hairline md:text-md text-indigo-800 py-4 pt-8 z-10" style={{ textAlign: 'center' }}>
          <span class="font-bold bg-indigo-100 rounded p-2">#1</span> <span class="line-through">UBER</span> for health professionals in Nigeria
        
        </h1>
       
      <div class="flex flex-row flex-wrap infolist">
        <div className="img-containers infoimage" ></div>
        <div class="text-conts ">
          <div class="flex items-center pl-1 slide-in-bottom-h1 py-2">
            <img src={doctor} className="info-icons info-icon-mobile" />
            <p class="text-gray-700 m-0 font-thin text-md md:text-xl hover:text-indigo-900 hover:font-bold">
              Increased efficiency for health professionals</p>
          </div>
          <div class="flex items-center pl-1 slide-in-bottom-subtitle py-4">
            <img src={hospital} className="info-icons info-icon-mobile" />
            <p class="text-gray-700 m-0 font-thin text-md md:text-xl hover:text-indigo-900 hover:font-bold">
              Seamless flow of health information</p>
          </div>
          <div class="flex items-center pl-1 slide-in-bottom-subtitle1 py-4">
            <img src={insurance} className="info-icons info-icon-mobile" />
            <p class="text-gray-700 m-0 font-thin text-md md:text-xl hover:text-indigo-900 hover:font-bold">
              Patients playing an active role in managing their health</p>
          </div>
          <div class="flex items-center pl-1 slide-in-bottom-subtitle2 py-2">
            <img src={shield} className="info-icons info-icon-mobile" />
            <p class="text-gray-700 m-0 font-thin text-md md:text-xl hover:text-indigo-900 hover:font-bold">
              Improved healthcare experiences</p>
          </div>
        </div>
      </div>
      
    </div>
)
}

export default Feat
