import React from 'react'
import { Link } from 'gatsby'
import ReactModal from 'react-modal'

import styled from 'styled-components';
import docpatient from '../../images/doctor-and-patient.png'
import medhelp from '../../images/medhelp.png'
import scientist from '../../images/scientist.png'
import medlab from '../../images/medlab.png'
import seedemo from '../../images/see-demo.png'
import partnership from '../../images/partnership.png'
import { BiChevronRight } from 'react-icons/bi'
import {IoIosCloseCircleOutline} from 'react-icons/io'
import Contact from '../Forms/contact'

const Container = styled.div`
  ${tw`container mx-auto flex flex-col px-8 my-10 justify-center`};
`
const StyledLink = styled(Link)`
  ${tw`self-center`};
`
ReactModal.setAppElement('#___gatsby')


function isMobileDevice() {
	if (typeof window !== 'undefined') {
	return ( window.orientation > -1) || (navigator.userAgent.indexOf('IEMobile') !== -1);
	}
};

const customStyles = {
	content : {
	  top                   : isMobileDevice() ? '52%' : '50%',
	  left                  : '50%',
	  right                 : 'auto',
	  bottom                : 'auto',
	  marginRight           : '-50%',
	  transform             : 'translate(-50%, -50%)',
	  height:  500,
	  width: isMobileDevice() ? '90%' : '55%',
	},
	overlay: {zIndex: 50}
  };


const Services = () => {

    const [openModal, setModal] = React.useState(false)

	function handleModal(){
		setModal(!openModal)
	} 

    return (
        <Container>
            <ReactModal 
                isOpen={openModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={handleModal}
                style={customStyles}
                > 
			<button class="flex justify-self-end self-end absolute top-0 pt-2 cursor-pointer z-50" onClick={handleModal}> 
			<IoIosCloseCircleOutline class="text-gray-600"/>
			</button>
			<br/>
			<Contact />

		</ReactModal>
            <h1 class="font-hairline md:text-md text-indigo-800 py-4 pt-8" style={{ textAlign: 'center' }}>
                Transforming healthcare delivery across the circle of care
            </h1>
            <div class="grid grid-col-1 gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-10 lg:gap-y-20">
                <div class="bg-gray-200 h-32  block slide-in-bottom">
                    <span class="text-gray-900 p-2 pl-4 text-sm font-medium "> Access medical professionals instantly</span>
                    
                    <div class="flex h-full bg-contain bg-no-repeat ml-4 justify-between" style={{backgroundImage:`url(${medhelp})`}}>
                        <span></span> 
                        <StyledLink to="/patients">
                            <span class="flex cursor-pointer text-sm text-gray-600 hvr-push fxDsp">Talk to a doctor <BiChevronRight fontSize={24}/></span>
                        </StyledLink>
                    </div>
                </div>
                <div class="bg-gray-200 h-32  block slide-in-bottom-h1">
                    <span class="text-gray-900 p-2 pl-4 text-sm font-medium"> Offer consultations at your own price</span>
                    
                    <div class="flex h-full bg-contain bg-no-repeat ml-4 justify-between" style={{backgroundImage:`url(${docpatient})`}}>
                        <span></span> 
                        <StyledLink to="/medical-professionals">
                            <span class="flex cursor-pointer text-sm text-gray-600 hvr-push fxDsp">Join as a medical professional <BiChevronRight fontSize={24}/></span>
                        </StyledLink>
                    </div>
                </div>
                <div class="bg-gray-200 h-32  block slide-in-bottom-subtitle">
                    <span class="text-gray-900 p-2 pl-4 text-sm font-medium"> Get paid to dispatch test samples</span>
                    
                    <div class="flex h-full bg-contain bg-no-repeat ml-4 justify-between" style={{backgroundImage:`url(${scientist})`}}>
                        <span></span> 
                        <StyledLink to="/medical-labs">
                            <span class="flex cursor-pointer text-sm text-gray-600 hvr-push fxDsp">Apply as a dispatch scientist <BiChevronRight fontSize={24}/></span>
                        </StyledLink>
                    </div>
                </div>
                <div class="bg-gray-200 h-32  block slide-in-bottom-subtitle1">
                    <span class="text-gray-900 p-2 pl-4 text-sm font-medium"> Join our growing list of labs & pharmacies</span>
                    
                    <div class="flex h-full bg-contain bg-no-repeat ml-4 justify-between" style={{backgroundImage:`url(${medlab})`}}>
                        <span></span> 
                        <StyledLink to="/medical-labs">
                            <span class="flex cursor-pointer text-sm text-gray-600 hvr-push fxDsp">Onboard with us <BiChevronRight fontSize={24}/></span>
                        </StyledLink>
                    </div>
                </div>
                <div class="bg-gray-200 h-32  block">
                    <span class="text-gray-900 p-2 pl-4 text-sm font-medium slide-in-bottom-subtitle2"> NGO, HMO & Health Insurance Agencies</span>
                    
                    <div class="flex h-full bg-contain bg-no-repeat ml-4 justify-between" style={{backgroundImage:`url(${seedemo})`}}>
                        <span></span> 
                        <div class="self-center" onClick={handleModal}>
                            <span class="flex cursor-pointer text-sm text-gray-600 hvr-push fxDsp">Request a demo <BiChevronRight fontSize={24}/></span>
                        </div>
                    </div>
                </div>
                <div class="bg-gray-200 h-32  block">
                    <span class="text-gray-900 p-2 pl-4 text-sm font-medium slide-in-bottom-subtitle2"> Got a related business venture ?</span>
                    
                    <div class="flex h-full bg-contain bg-no-repeat ml-4 justify-between" style={{backgroundImage:`url(${partnership})`}}>
                        <span></span> 
                        <div class="self-center" onClick={handleModal}>
                            <span class="flex cursor-pointer text-sm text-gray-600 hvr-push fxDsp">Partner with us <BiChevronRight fontSize={24}/></span>
                        </div>
                    </div>
                </div>

            </div>
        </Container>
            )
}

export default Services