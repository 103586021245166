import React, { useState } from 'react';
import covidquestion from '../../images/covid-stats.png';
import covidstop from '../../images/stop-covid.png';
import ReactModal from 'react-modal'

import {IoIosCloseCircleOutline} from 'react-icons/io'
import Contact from '../Forms/contact'


ReactModal.setAppElement('#___gatsby')

function isMobileDevice() {
	if (typeof window !== 'undefined') {
	return ( window.orientation > -1) || (navigator.userAgent.indexOf('IEMobile') !== -1);
	}
};

const customStyles = {
	content : {
	  top                   : isMobileDevice() ? '52%' : '50%',
	  left                  : '50%',
	  right                 : 'auto',
	  bottom                : 'auto',
	  marginRight           : '-50%',
	  transform             : 'translate(-50%, -50%)',
	  height:  500,
	  width: isMobileDevice() ? '90%' : '55%',
	},
	overlay: {zIndex: 50}
  };

const Covid = () => {
    const [openModal, setModal] = React.useState(false)

	function handleModal(){
		setModal(!openModal)
	} 

return (
    <div class="w-full px-4 md:px-8 block">
        <ReactModal 
                isOpen={openModal}
                // onAfterOpen={afterOpenModal}
                onRequestClose={handleModal}
                style={customStyles}
                > 
			<button class="flex justify-self-end self-end absolute top-0 pt-2 cursor-pointer z-50" onClick={handleModal}> 
			<IoIosCloseCircleOutline class="text-gray-600"/>
			</button>
			<br/>
			<Contact />

		</ReactModal>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-1 items-center justify-center">
        <div>
        <img src={covidstop} class="w-full md:w-4/6" /></div>
        <span class="text-wrap flex text-2xl md:text-4xl font-light text-gray-900 tracking-wide leading-relaxed"> Let's protect your employees and customers 
        from COVID-19 to compliment your exceptional customer service.</span>
           
    </div>
    
    <div class="flex flex-col md:flex-row md:mx-8 gap-2 items-center justify-center">
         <div class="w-full md:w-1/2 text-wrap flex flex-col px-1  font-extra-light text-gray-700 tracking-normal leading-loose"> 
                <div class="mb-2">
                <span class="text-justify text-sm md:text-lg"> With our AI rapid response virtual chat agent. <br />
                Deploy our bot on your wesbite to enable your employees and customers 
                get accurate responses to COVID related questions in realtime. <br />
                Usage - Banks, Health facilities, Corporate organizations and Government agencies </span> </div>
                <span onClick={handleModal} class="p-1 px-2 bg-indigo-600 text-white cursor-pointer text-md rounded self-center md:self-start">Get your bot</span>
         </div>
    <div class="w-full hidden md:flex md:w-1/2 justify-center ">
        <img src={covidquestion} class="w-10/12 " /></div>
       
    </div>
    </div>
)
}

export default Covid