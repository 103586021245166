import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';
import mobileapp from '../../images/mobile.png'
import welcome from '../../images/IMG_ph12.png'
import appStore from '../../images/App Store.svg'
import playStore from '../../images/Play Store.svg'
import './dltest.css'


const Dltest = () => (




<div class="fade-in">

    <div>
    <img src={welcome} class="absolute welcome swing mx-2 dlimage"/>
    </div>
    <div className="popup-innerdl py-8 px-4 bg-gradient-to-r from-pink-500 to-orange-500 hover:from-indigo-400 to-purple-300">
      {/* <div className="popup__photo">
        <img src={patientapp} class="swing"/>
      </div> */}
      <div  class="items-start text-center">
        <h1 class="headh1 text-black" >GET STARTED</h1>
        <h1 class="headh1 text-black" >DOWNLOAD OUR PATIENT APP</h1>
        <div class=" text-center">
             <a href="#" >
                 <img src={appStore} class="h-10 mr-5 dlicon hvr-push"   />
                     </a>
                     <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpng">
                         <img src={playStore} class="h-10 dlicon hvr-push" />
                             </a>
            </div>
      </div>
     
    </div>
    <div class="mx-4 my-3 md:hidden">
    <img src={mobileapp} class=" mx-2 "/>
    </div>
    <div className="popup-innerdl2 py-8 px-4 justify-between bg-gradient-to-l from-indigo-400 to-purple-300 hover:from-pink-500 to-orange-500">
    <div>

    </div>
    <div class="text-center">
        <h1 class="headh1 text-black" >JOIN US NOW</h1>
        <h1 class="headh1 text-black" >DOWNLOAD CONSULTANT APP</h1>
        <div class=" text-center">
             <a href="https://testflight.apple.com/join/4r4wzfWx" >
                 <img src={appStore} class="h-10 mr-5 dlicon hvr-push"   />
                     </a>
                     <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpconsultant">
                         <img src={playStore} class="h-10 dlicon hvr-push" />
                      </a>
            </div>
      </div>

      {/* <div className="popup__photo2">
        <img src={consultantapp} class="swing"/>
      </div> */}
     
      </div>




    </div>



)
  export default Dltest
  